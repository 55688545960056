import Home from './Pages/Home'
import React from 'react'
import './Styles/base.scss'

function App() {
  return (
    <div>
      <Home />
    </div>
  )
}

export default App
